import React, {useMemo} from "react"
import parse, { domToReact } from "html-react-parser"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Sections from "../components/Sections"
import Block from "../components/Block"
import FormSimple from "../components/FormSimple"
import Helmet from "react-helmet"
import ContactForm from "../components/ContactForm"
import ContentImage from "../components/ContentImage"
import GoogleMap from "../components/GoogleMap"
import WeatherStatus from "../components/WeatherStatus"
import ContentContext from "../context/ContentContext"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import favicon from "../../static/favicon.svg"
import { useLocation } from "@reach/router";
import { setPathCanonical } from "../components/lib/utils";
import BookingManagement from "../components/BookingManagement"
import {I18nextContext} from "gatsby-plugin-react-i18next";

const invertedPages = [
  "parque-rural-de-teno",
  "caserio-de-masca",
  "visitar-masca",
  "barranco-de-masca",
]
const pagesWithFooter = [
  "por-tu-seguridad",
  "conservacion",
  "equipamiento",
  "como-llegar",
]

function nextElementSibling(elem) {
  let node = elem.next
  while (node !== null && node.type !== "tag") node = node.next
  return node
}

export const query = graphql`
  query ArticleDetails($lang: String, $articleId: Int) {
    localizedArticle(lang: { eq: $lang }, articleId: { eq: $articleId }) {
      remoteImage {
        childImageSharp {
          fixed(width: 1110, height: 400, fit: COVER, cropFocus: CENTER) {
            src
          }
        }
      }
      siteBaseUrl
      lang
      name
      section
      seo_desc
      slug
      originalPath
      originalSlug
      subtitle
      articleId
      full_text
      seo_title
      translations {
        lang
        path
      }
    }
    site {
      siteMetadata {
        noIndexArticles
        appAPIKey
        statusAPIURL
        googleReCaptchaAPIKey
				termsAndConditionsArticle
				environment
			}
		}
		allLocalizedArticle {
			edges {
        node {
          articleId
          lang
          path
        }
      }
    }
  }
`

export default ({ data }) => {
  const { contactFormURL, siteUrl } = useSiteMetadata();
  const article = data.localizedArticle;
  const location = useLocation();
  const { language } = React.useContext(I18nextContext);

  const replaceOptions = {
    trim: true,
    replace: domNode => {
      if (
        domNode.name === "img" &&
        domNode.attribs.src &&
        domNode.attribs.src.startsWith("/")
      ) {
        return <ContentImage image={domNode} />
      } else if (domNode.attribs && domNode.attribs.class === "block") {
        return <Block refs={domNode.attribs.refs.split(",").map(x => +x)} />
      } else if (domNode.attribs && domNode.attribs.class === "sections") {
        if (pagesWithFooter.includes(article.originalSlug)) {
          return (
            <Sections
              refs={domNode.attribs.refs.split(",").map(x => +x)}
              message="NO_OLVIDES"
            />
          )
        } else {
          return (
            <Sections refs={domNode.attribs.refs.split(",").map(x => +x)} />
          )
        }
      } else if (domNode.attribs && domNode.attribs.class === "info-contacto") {
        const sibling = nextElementSibling(domNode)
        if (sibling && sibling.attribs.class === "info-contacto") {
          return (
            <div className="row">
              <div className="info-contacto col-12 col-lg-6">
                {domToReact(domNode.children, replaceOptions)}
              </div>
              <div className="info-contacto col-12 col-lg-6">
                {domToReact(domNode.next.next.children, replaceOptions)}
              </div>
            </div>
          )
        } else {
          return <React.Fragment />
        }
      } else if (domNode.attribs && domNode.attribs.class === "contact-form") {
        return (
          <ContactForm id="contact-form" url={contactFormURL} data={data.site} allLocalizedArticle={data.allLocalizedArticle}></ContactForm>
        )
      } else if (
        domNode.attribs &&
        domNode.attribs.class === "contact-page-map"
      ) {
        return (
          <GoogleMap
            lat={parseFloat(domNode.attribs.lat.replace(",", "."))}
            lng={parseFloat(domNode.attribs.lng.replace(",", "."))}
          ></GoogleMap>
        )
      } else if (domNode.attribs && domNode.attribs.class === "masca-today") {
        return (
          <div className={`row`}>
            <WeatherStatus
              classes={"col-12 col-lg-10 offset-lg-1"}
              statusAPIURL={data.site.siteMetadata.statusAPIURL}
              apiKey={data.site.siteMetadata.appAPIKey}
            ></WeatherStatus>
          </div>
        )
      } else if (
        domNode.name === "table" &&
        !domNode.attribs.class &&
        article.originalSlug !== "por-tu-seguridad"
      ) {
        domNode.attribs.class = "table"
      } else if (domNode.attribs && domNode.attribs.class === "btn-cta") {
        return (
          <div className={`text-center`}>
            <Link
              to={domNode.attribs.href}
              target="_blank"
              className="btn btn-primary btn-movil"
            >
              {domToReact(domNode.children, replaceOptions)}
            </Link>
          </div>
        )
      }
    },
  }

  const noIndex = data.site.siteMetadata.noIndexArticles.some(
    noIndexArticleId => article.articleId === noIndexArticleId
  )
  const memoizedValue = useMemo(() => ({ translations: article.translations }), [article.translations]);
  return (
    <ContentContext.Provider value={memoizedValue}>
      <Layout>
        <Helmet>
          <html lang={language}/>
          <title>{article.seo_title}</title>
          <link rel="icon" href={favicon}/>
          <meta name="title" content={article.seo_title}/>
          <meta name="description" content={article.seo_desc}/>
          <meta name="og:title" content={article.seo_title}/>
          <meta name="og:description" content={article.seo_desc}/>
          {article.remoteImage &&
            <meta name="og:image" content={siteUrl + article.remoteImage?.childImageSharp.fixed.src}/>}
          <meta name="og:type" content="article"/>
          <meta name="og:url" content={setPathCanonical(location.href)}/>
          {article.translations.map((translation) => <link rel="alternate" key={translation.lang}
                                                           hrefLang={translation.lang}
                                                           href={siteUrl + translation.path + "/"}/>)}
          <link rel="canonical" href={setPathCanonical(location.href)}/>
          {noIndex && <meta name="robots" content="none"/>}
        </Helmet>
        <div className="espacio-blanco"></div>
        {invertedPages.includes(article.originalSlug) && (
          <h1 className="titulo-pre">{article.name}</h1>
        )}
        {article.remoteImage && article.articleId !== 134 && (
          <div
            className={`imagen-superior${article.originalSlug === "gestiona-tu-reserva" ? " form" : ""
              }`}
            style={{
              backgroundImage: `url(${article.remoteImage.childImageSharp.fixed.src})`,
            }}
          >
            {article.subtitle &&
              parse(article.subtitle, {
                replace: domNode => {
                  if (
                    domNode.attribs &&
                    domNode.attribs.class === "form_simple"
                  ) {
                    return (
                      <FormSimple
                        article={article}
                        url={domNode.attribs.url}
                        fields={domNode.attribs.fields}
                      />
                    )
                  }
                },
              })}
          </div>
        )}
        <article
          className={`pt-5 pb-5 articulo text-justify page page-${article.originalSlug}`}
        >
          {!article.remoteImage &&
            article.originalSlug !== "masca-hoy" &&
            parse(article.subtitle)}
          {!invertedPages.includes(article.originalSlug) && (
            <h1 className={`text-center`}>{article.name}</h1>
          )}
          {article.subtitle &&
            parse(article.subtitle, {
              replace: domNode => {
                if (
                  domNode.attribs &&
                  domNode.attribs.class === "booking-management"
                ) {
                  return (
                    <BookingManagement />
                  )
                }
              },
            })}
          {article.originalSlug === "masca-hoy" && (
            <div className={`text-center`}>{parse(article.subtitle)}</div>
          )}
          {parse(article.full_text, replaceOptions)}
        </article>
      </Layout>
    </ContentContext.Provider>
  )
}
