import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import parse from 'html-react-parser';


class FormSimple extends Component {
  render() {
	const article = this.props.article;
	return <form className="form-simple" method="POST" action={this.props.url}>
		<div className="form">
			<span className={`icon-${article.orginalSlug}`}></span>
			<h3>{article.name}</h3>
			{parse(article.subtitle)}
			{this.props.fields.split('|').map(field => {
				const fieldData = field.split('¬');
				return <div className="form-group">
					<label for={fieldData[0]}>{fieldData[1]}</label>
					<input type="text" class="form-control" id={fieldData[0]} name={fieldData[0]} required />
				</div>
			})}
		</div>
		<input type="submit" style={{display: "none"}} />
	</form>
  }
}

export default withTranslation('FormSimple')(FormSimple);