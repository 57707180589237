import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { VolcanoBookingManagementWidget } from "@volcanoteide/vte-components"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import UIkit from 'uikit'

class BookingManagement extends Component {
    
	componentDidMount() {
		if (typeof window !== `undefined`) {
			UIkit.container = '.uk-scope';
		}
	}

	render() {
		return (
            <StaticQuery
                query={graphql`
                    query BookingManagementVolcanoTeideAPIConfig {
                        site {
                            siteMetadata {
                                volcanoTeideAPIConfig {
                                    protocol
                                    host
                                    port
                                    strictSSL
                                    timeout
                                    site_key
                                }
                            }
                        }
                    }
                `}
                render={data => {
                        return (<VolcanoBookingManagementWidget
                            locale={this.props.i18n.language}
                            apiConfig={data.site.siteMetadata.volcanoTeideAPIConfig}
                            />
                        )
                    }
                }
            />
        )
	}
}

export default withTranslation('bookingManagement')(BookingManagement);